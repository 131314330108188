import { createRouter, createWebHistory } from 'vue-router'

const routes = [{
  path: '/',
  component: () => import('@/views/index.vue'),
  redirect: '/index',
  children: [
    {
      path: '/index',
      name: 'index',
      component: () => import('@/views/index/index.vue'),
      meta: {
        title: '概览'
      }
    },
    {
      path: '/userplatform',
      name: 'userplatform',
      component: () => import('@/views/index/user/userplatform.vue'),
      meta: {
        title: '平台用户管理'
      }
    },
    {
      path: '/useroperator',
      name: 'useroperator',
      component: () => import('@/views/index/user/useroperator.vue'),
      meta: {
        title: '洗车员管理'
      }
    },
    {
      path: '/usersuse',
      name: 'usersuse',
      component: () => import('@/views/index/user/usersuse.vue'),
      meta: {
        title: '洗车用户管理'
      }
    },
    {
      path: '/provinces',
      name: 'provinces',
      component: () => import('@/views/index/location/provinces.vue'),
      meta: {
        title: '城市管理'
      }
    },
    {
      path: '/regions',
      name: 'regions',
      component: () => import('@/views/index/location/regions.vue'),
      meta: {
        title: '区域管理'
      }
    },
    {
      path: '/communitys',
      name: 'communitys',
      component: () => import('@/views/index/location/communitys.vue'),
      meta: {
        title: '小区管理'
      }
    },
    {
      path: '/devices',
      name: 'devices',
      component: () => import('@/views/index/device/devices.vue'),
      meta: {
        title: '设备管理'
      }
    },
    {
      path: '/brandsmodels',
      name: 'brandsmodels',
      component: () => import('@/views/index/Car/brandsmodels.vue'),
      meta: {
        title: '汽车品牌车型管理'
      }
    },
    {
      path: '/cars',
      name: 'cars',
      component: () => import('@/views/index/Car/cars.vue'),
      meta: {
        title: '车辆管理'
      }
    },
    {
      path: '/informations',
      name: 'informations',
      component: () => import('@/views/index/business/informations.vue'),
      meta: {
        title: '信息管理'
      }
    },
    {
      path: '/sales',
      name: 'sales',
      component: () => import('@/views/index/business/sales.vue'),
      meta: {
        title: '价格管理'
      }
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/index/business/orders.vue'),
      meta: {
        title: '订单管理'
      }
    },
    {
      path: '/statsusers',
      name: 'statsusers',
      component: () => import('@/views/index/stats/statsusers.vue'),
      meta: {
        title: '用户统计'
      }
    },
    {
      path: '/statsdevices',
      name: 'statsdevices',
      component: () => import('@/views/index/stats/statsdevices.vue'),
      meta: {
        title: '设备统计'

      }
    },
    {
      path: '/statsregions',
      name: 'statsregions',
      component: () => import('@/views/index/stats/statsregions.vue'),
      meta: {
        title: '区域统计'
      }
    },
    {
      path: '/loggerdevices',
      name: 'loggerdevices',
      component: () => import('@/views/index/logger/loggerdevices.vue'),
      meta: {
        title: '设备日志'
      }
    },
    {
      path: '/loggerusers',
      name: 'loggerusers',
      component: () => import('@/views/index/logger/loggerusers.vue'),
      meta: {
        title: '用户日志'
      }
    },
    {
      path: '/useroperatorfeedbacks',
      name: 'useroperatorfeedbacks',
      component: () => import('@/views/index/feedbacks/useroperatorfeedbacks.vue'),
      meta: {
        title: '洗车员反馈'
      }
    },
    {
      path: '/userusefeedbacks',
      name: 'userusefeedbacks',
      component: () => import('@/views/index/feedbacks/usersusefeedbacks.vue'),
      meta: {
        title: '用户反馈'
      }
    },
    {
      path: '/loggerpayment',
      name: 'loggerpayment',
      component: () => import('@/views/index/logger/loggerpayment.vue'),
      meta: {
        title: '支付日志'
      }
    }
  ]
}, {
  path: '/login',
  name: 'login',
  component: () => {
    return import('@/views/login.vue')
  },
  meta: {
    title: '登录'
  }
}]

const router = createRouter({
  mode: 'history',
  base: '/',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  const token = localStorage.getItem('token')
  if (to.path === '/login') {
    next()
  } else if (to.path === '/page') {
    next()
  } else if (token === null) {
    next('/login')
  } else {
    next()
  }
})
export default router
